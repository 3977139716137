/* --------------------------------------------------------------------------

--------------------------------------------------------------------------- */

.promo,.touristInfo {
    padding: 10rem 0;
    @include mq(sm) {
        padding: 7rem 0;
    }
}

.promo {
    background-color: white;
}

.touristInfo {
    margin-bottom: 10rem;
}
