





































































































.p-slider-body {
    img {
        width: 100%;
    }
}
.c-pnCard {
    &__no-link {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: .2s all;
        padding-bottom: 4rem;
        border-bottom: 2px solid #ECECEC;
        position: relative;
    }
}
