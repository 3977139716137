/* --------------------------------------------------------------------------
  site-footer
--------------------------------------------------------------------------- */

.l-footer {
    color: white;
    background-color: $clr-sub01;
    padding: 5rem 5% 6rem;

    &__logo {
        display: block;
        width: 5rem;
        margin: 0 auto 3rem;
    }

    &__sns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 3rem;
        li {
            margin: 0 .5rem;
            a {
                display: block;
                width: 4rem;
                height: 4rem;
                line-height: 4.3rem;
                text-align: center;
                border-radius: 50%;
                font-size: 1.8rem;
                transition: .2s all;
                &:hover {
                    background-color: darken($clr-sub01,3%);
                }
                i { margin-left: 2px; }
            }
        }
    }

    .copyright {
        display: block;
        font-size: 1.2rem;
        text-align: center;
    }
}
