/* --------------------------------------------------------------------------
  routeList
--------------------------------------------------------------------------- */

.routeList {
    &-item {
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 0;
            height: 100%;
            border-left: 2px solid $clr-act01;
            position: absolute;
            top: 4.5rem;left: calc(6% + 6px);
            z-index: 10;
            @include mq(sm) {
                top: 2.5rem;left: calc(4% + 5px);
            }
        }

        &:last-child {
            &::before { display: none; }
        }

        &__place {
            font-size: 1.7rem;
            font-weight: 700;
            letter-spacing: .15em;
            background-color: $gray01;
            padding: 3rem 6%;
            @include mq(sm) {
                padding: 2rem 4%;
            }
            span {
                display: block;
                padding-left: 3rem;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 1.4rem;
                    height: 1.4rem;
                    background-color: $clr-act01;
                    border-radius: 50%;
                    box-shadow: 0 0 0 6px rgba($clr-act01,.3);
                    position: absolute;
                    top: .25em;left: 0;
                    @include mq(sm) {
                        width: 1.2rem;
                        height: 1.2rem;
                        box-shadow: 0 0 0 5px rgba($clr-act01,.3);
                    }
                }
            }
        }


        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 5% 2rem 13%;
            font-size: 1.5rem;
            line-height: 1.6;
            position: relative;
            z-index: 10;
            @include mq(md) {
                padding: 2rem 5% 3rem 15%;
            }
            @include mq(sm) {
                flex-wrap: wrap;
                padding: 6.5rem 5% 3rem 12%;
            }

            .time {
                display: block;
                background-color: white;
                border: 2px solid $clr-act01;
                font-weight: 700;
                position: absolute;
                top: 50%;left: calc(6% + 6px);
                transform: translate(-50%,-50%);
                padding: .54em .6em .4em .8em;
                border-radius: .5rem;
                font-size: 1.5rem;
                letter-spacing: 1px;
                text-indent: 1px;
                line-height: 1;
                @include mq(sm) {
                    top: 1.5rem; left: calc(4% + 5px);
                    transform: translate(0,0);
                    border-radius: 0 .5rem .5rem 0;
                    padding: .74em .8em .6em 1em;
                    font-size: 1.2rem;
                }

                span {
                    display: inline-block;
                    font-size: 160%;
                    margin: 0 1px 0 0;
                    vertical-align: -1px;
                }
            }

            .rid-detail  {
                width: 100%;
                margin-right: 5%;
                @include mq(sm) {
                    line-height: 1.4;
                    margin: 0 0 1.5rem;
                }

                div,p {
                    @include mq(sm) {
                        font-size: 1.4rem;
                        margin-bottom: .5rem;
                    }
                }

                .note {
                    font-size: 1.3rem;
                    background-color: #F8F8F8;
                    border: 1px solid #E5E5E5;
                    padding: 3%;
                    border-radius: 5px;
                    margin: 1rem 0 0;
                    @include mq(sm) {
                        padding: 5%;
                    }

                    p,div {
                        @include mq(sm) {
                            font-size: 1.3rem;
                        }
                    }

                    a { margin: 0 0 1rem; }

                    p + a,
                    a + p,
                    p + p,
                    p + div { margin: 1rem 0 0; }

                    p + div a { margin: 0; }

                    .btn-right { text-align: right; }
                }
            }

            .rid-right {
                @include mq(sm) {
                    margin-left: auto;
                }
            }
        }

        .caution {
            color: white;
            font-weight: 500;
            padding: .5em 1em;
            border-radius: 3px;
            background-color: $clr-caution;
            margin: 0 0 1rem;
        }
    }

    &-map {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        @include mq(sm) {
            padding-bottom: 120%;
        }
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
}
