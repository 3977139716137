





















































































































.p-slider-body {
    img {
        width: 100%;
    }
}
.c-tiCard {
    &__no-link {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: .2s all;
    }
}
