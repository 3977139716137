%hidetext {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

%fs-mincho {
    font-family: 'Hiragino Mincho ProN','Noto Serif JP',HGS明朝E,メイリオ,Meiryo,serif;
}

%fs-gothic {
    font-family: 'Roboto','Noto Sans JP',-apple-system,BlinkMacSystemFont,'Helvetica Neue','Segoe UI','Hiragino Kaku Gothic ProN','メイリオ',meiryo,sans-serif;
}

%fs-oswald {
    font-family: 'Oswald', sans-serif;
}

// 選択不可にする
%selectable {
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
