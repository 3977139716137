@font-face {
    font-family: 'tdmo_icons';
    src:  url('../fonts/tdmo_icons.eot?dm1c2c');
    src:  url('../fonts/tdmo_icons.eot?dm1c2c#iefix') format('embedded-opentype'),
    url('../fonts/tdmo_icons.ttf?dm1c2c') format('truetype'),
    url('../fonts/tdmo_icons.woff?dm1c2c') format('woff'),
    url('../fonts/tdmo_icons.svg?dm1c2c#tdmo_icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'tdmo_icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-airplane:before {
    content: "\e900";
}
.icon-arrow_left:before {
    content: "\e901";
}
.icon-arrow_right_round:before {
    content: "\e902";
}
.icon-arrow_right:before {
    content: "\e903";
}
.icon-bus:before {
    content: "\e904";
}
.icon-car:before {
    content: "\e905";
}
.icon-ex_link:before {
    content: "\e906";
}
.icon-facebook:before {
    content: "\e907";
}
.icon-infomation01:before {
    content: "\e908";
}
.icon-infomation02:before {
    content: "\e909";
}
.icon-instagram:before {
    content: "\e90a";
}
.icon-map_pin:before {
    content: "\e90b";
}
.icon-shinkansen:before {
    content: "\e90c";
}
.icon-taxi:before {
    content: "\e90d";
}
.icon-time_table:before {
    content: "\e90e";
}
.icon-train:before {
    content: "\e90f";
}
.icon-twitter:before {
    content: "\e910";
}
.icon-walk:before {
    content: "\e911";
}
