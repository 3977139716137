

















.fade {
    &-enter-active, &-leave-active {
        will-change: opacity;
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &-enter, &-leave-to {
        opacity: 0;
    }
}
