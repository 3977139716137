html {
    text-rendering: optimizeLegibility;
    font-size: 62.5%;
    background-color: $clr-mvbg;
    // background: -moz-linear-gradient(top,  #dfeff2 0%, #dfeff2 50%, #282c37 50%, #282c37 100%);
    // background: -webkit-linear-gradient(top,  #dfeff2 0%,#dfeff2 50%,#282c37 50%,#282c37 100%);
    // background: linear-gradient(to bottom,  #dfeff2 0%,#dfeff2 50%,#282c37 50%,#282c37 100%);
}


* {
    zoom: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    &:focus {
        outline: none;//chrome
    }
}

*:before,*:after { zoom: 1; }

::selection { background: rgba(#EEA958,.4); }

body {
    @extend %fs-gothic;
    color: $clr-sub01;
    word-break: normal;
    vertical-align: baseline;
    text-align: justify;
    text-justify: inter-ideograph;
    font-size: 14px;
    line-height: 1.4;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    letter-spacing: .1em;
    background-color: $clr-sub02;
}

*:focus,*:active { outline: none !important; }
.is-no-scroll { overflow: hidden; }

.inr {
    width: 94%;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    @include mq(md) { max-width: 700px; }
}

.cl-text {
    text-align: center;
    &-inr {
        display: inline-block;
        text-align: left;
    }
}
