/* --------------------------------------------------------------------------
  modal
--------------------------------------------------------------------------- */

.modal-overlay {
    display: block;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0);
    transition: all .2s ease;
    z-index: 900;

    .is-modal-open & {
        visibility: visible;
        cursor: pointer;
        background: rgba($clr-sub01,.7);
    }
}

.p-modal {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0; left: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 920;

    &.is-modal-scroll {
        padding: 6rem 0;
        align-items: flex-start;
        overflow: scroll;
    }

    &-inr {
        width: 100%;
        position: relative;
        opacity: 0;
        transform: translateY(-3rem);
        transition: all .3s ease;
        @include mq(sm) { transform: translateY(-1.5rem); }
    }

    &.is-open {
        .p-modal-inr {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &-close {
        width: 4rem;
        height: 4rem;
        background-color: #F4F4F4;
        border-radius: 50%;
        position: absolute;
        top: 50%; right: 3rem;
        transform: translateY(-50%);
        transition: .2s all;
        @include mq(sm) {
            top: 1rem;
            right: 1rem;
            transform: translateY(0);
        }
        &:hover {
            background-color: darken(#F4F4F4,5%);
        }
        &:active {
            transform: translateY(-50%) scale(.95);
        }
        .cross{
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 auto;
            position: relative;
            &::before,&::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #000;
                position: absolute;
                top: 2px;
            }
            &::before {
                transform: rotate(45deg);
                transform-origin:0% 50%;
                left: 2px;
            }
            &::after{
                transform: rotate(-45deg);
                transform-origin:100% 50%;
                right: 2px;
            }
        }
    }
}


.timetable {
    position: relative;
    width: 94%;
    max-width: 700px;
    margin: 0 auto;
    pointer-events: auto;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;

    &-header {
        display: flex;
        align-items: center;
        padding: 2.5rem 5%;
        border-bottom: 1px solid $clr-sub01;
        position: relative;
        @include mq(sm) {
            display: block;
        }

        .icon {
            width: 6rem;
            height: 6rem;
            line-height: 6.5rem;
            font-size: 2.5rem;
            color: white;
            text-align: center;
            background-color: $clr-sub01;
            border-radius: 50%;
            margin-right: 2rem;
            padding-left: 2px;
            @include mq(sm) {
                margin: 0 auto 2rem;
            }
        }

        .bus-stop {
            font-size: 2.1rem;
            font-weight: 700;
            margin: 0 0 .3rem;
            @include mq(sm) {
                text-align: center;
                &-inr {
                    display: inline-block;
                    text-align: left;
                }
            }
        }

        .c-name {
            font-size: 1.5rem;
            @include mq(sm) {
                text-align: center;
                &-inr {
                    display: inline-block;
                    text-align: left;
                }
            }
        }
    }

    .tt-tabBtn {
        color: $gray02;
        font-size: 2.1rem;
        font-weight: 700;
        letter-spacing: .15em;
        text-indent: .15em;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.2rem 3%;
        width: 50%;
        transition: .2s all;
        @include mq(sm) {
            font-size: 1.7rem;
        }
        &:hover {
            color: $clr-sub01;
        }
        &.is-tab-current {
            display: flex !important;
            color: white;
            background-color: $clr-sub01;
        }
    }

    &-table {
        display: table;
        width: 100%;
        .row {
            display: table-row;
            &:nth-child(odd) { background-color: $gray01; }
        }
        time {
            display: table-cell;
            width: 33.33333%;
            text-align: center;
            font-size: 3rem;
            font-weight: 700;
            padding: 2rem 1rem;
            border-left: 1px solid #EBEBEB;
            @include mq(sm) {
                font-size: 2.2rem;
            }
        }
    }
}
