/* --------------------------------------------------------------------------
  button
--------------------------------------------------------------------------- */

.btn-open-modal {
    white-space: nowrap;
    font-weight: 500;
    color: white;
    letter-spacing: .1em;
    text-indent: .1em;
    padding: 1.3rem 1.6rem 1.1rem;
    background-color: $clr-main;
    border-radius: 6px;
    box-shadow: 0 4px 0 darken($clr-main,10%);
    transform: translateY(0);
    transition: .1s all;
    @include mq(md) {
        font-size: 1.4rem;
        padding: 1.1rem 1.6rem .9rem;
    }

    &:active {
        transform: translateY(3px);
        box-shadow: 0 1px 0 darken($clr-main,10%);
    }

    i {
        display: inline-block;
        font-size: 120%;
        vertical-align: -15%;
        margin: 0 .5rem 0 0;
    }
}

.btn-ghost {
    display: inline-block;
    font-size: 1.2rem;
    color: $clr-main;
    font-weight: 500;
    padding: .6rem 2.5rem .5rem 1.5rem;
    background-color: white;
    border: 1px solid $clr-main;
    border-radius: 3rem;
    transition: .2s all;
    position: relative;

    &:hover {
        color: white;
        background-color: $clr-main;
        &::before { border-color: white;}
    }

    &::before {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-right: 2px solid $clr-main;
        border-bottom: 2px solid $clr-main;
        position: absolute;
        top: 50%; right: 1.3rem;
        transform: translateY(-50%) rotate(-45deg);
        transition: .2s all;
    }
}
