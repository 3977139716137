
// Settings color
// ========================================================================== //

$blue: #4DA5DC;
$aqua: #DFEFF2;
$light_blue: #F9FDFF;
$navy: #282C37;
$yellow: #FFC952;
$red: #FF7473;

$gray01: #F8F8F8;
$gray02: #B5B5B5;
$gray03: #ECECEC;

$clr-main: $blue !default;
$clr-act01: $yellow !default;
$clr-sub01: $navy !default;
$clr-sub02: $light_blue !default;
$clr-mvbg: $aqua !default;
$clr-caution: $red !default;

$breakpoints: (
    "sm": 640px,
    "md": 768px,
    "inr": 900px,
    "lg": 1100px,
    "0": 0,
) !default;

$breakpoints-re: mapReverse($breakpoints);
$grid-breakpoints: $breakpoints;
