





















.p-keyvisual-char {
    img {
        width: 100%;
    }
}
