/* --------------------------------------------------------------------------
  resultsSec
--------------------------------------------------------------------------- */

.resultsSec {
    margin: 0 0 15rem;
    @include mq(md) { margin: 0 0 6rem; }

    &-top {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 3rem;
        position: relative;

        &__arrow {
            width: 6rem;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%,-50%);
            @include mq(md) {
                transform: translate(-50%,-50%) rotate(90deg);
            }
        }
        .resultsSec-box {
            width: 49%;
            text-align: center;
            padding: 3.5rem 5%;
            @include mq(md) {
                width: 100%;
                margin: 1.5rem 0;
            }
        }
        .rst-icon {
            display: block;
            width: 4rem;
            margin: 0 auto 2rem;
        }
        .rst-place {
            font-size: 2.4rem;
            font-weight: 700;
            margin: 0 0 1.5rem;
            @include mq(sm) {
                font-size: 2.2rem;
            }
        }
        .rst-label {
            font-size: 1.7rem;
            font-weight: 500;
            color: $gray02;
            text-align: center;
        }
    }

    &-mid {
        margin: 0 0 3rem;
        .rsm-reqt {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            background-color: $clr-sub01;
            color: white;
            font-size: 1.7rem;
            padding: 3rem 5%;
            @include mq(sm) {
                font-size: 1.5rem;
                padding: 2rem 5% 1.5rem;
            }
            &__label {
                margin: 0 3rem 0 0;
                @include mq(sm) {
                    margin: 0 0 .5rem;
                }
            }
            &__time {
                font-size: 160%;
                font-weight: 700;
                @include mq(sm) {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .rsm-root {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3rem 3%;
            font-weight: 700;
            @include mq(md) {
                flex-wrap: wrap;
            }
            @include mq(sm) {
                padding: 2rem 3%;
            }

            &__item {
                position: relative;
            }

            &__place {
                font-size: 1.7rem;
                white-space: nowrap;
                padding: 0 1em;
                @include mq(md) {
                    font-size: 2rem;
                    padding: .5em 0;
                }
            }

            &__info {
                display: block;
                text-align: center;
                width: 100%;
                position: relative;
                z-index: 2;
                @include mq(md) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 9rem;
                }

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 16px;
                    background-image: radial-gradient(circle, $clr-main 30%, transparent 10%), radial-gradient(circle, $clr-main 30%, transparent 10%);
                    background-size: 16px 16px;
                    background-position: 0 0, 0 0;
                    position: absolute;
                    top: 50%; left: 0;
                    transform: translateY(-50%);
                    animation: line-move-x 15s linear infinite;
                    z-index: -1;
                    @include mq(md) {
                        width: 16px;
                        height: 100%;
                        animation: line-move-y 15s linear infinite;
                        top: 0; left: 50%;
                        transform: translateX(-50%);
                    }
                }



                &-inr {
                    @include mq(md) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .rinfo-icon {
                    font-size: 3rem;
                    @include mq(md) {
                        font-size: 2.8rem;
                        margin: -3px 4rem 0 4rem;
                    }
                }
                .rinfo-time {
                    display: block;
                    font-size: 1.7rem;
                    margin-top: 7rem;
                    @include mq(md) {
                        //margin: 0 0 0 0;
                        margin: 0 0 0 11rem;
                    }
                    span {
                        display: inline-block;
                        font-size: 160%;
                        margin: 0 2px 0 2px;
                        vertical-align: -2px;
                    }
                }
            }
        }
    }

    &-btm {
        transition: .2s all;

        .rsb-tabBtn {
            color: $gray02;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 2rem 3%;
            width: 50%;
            transition: .2s all;
            @include mq(sm) {
                padding: 2rem 3% 1rem;
            }
            &:hover {
                color: $clr-sub01;
            }
            &.is-tab-current {
                display: flex !important;
                color: white;
                background-color: $clr-sub01;
            }

            &__icon {
                font-size: 3rem;
                margin-right: 1rem;
                @include mq(sm) {
                    font-size: 2.5rem;
                    margin: 0 0 .5rem;
                }
            }
            &__txt {
                font-size: 2rem;
                text-indent: .2em;
                letter-spacing: .2em;
                font-weight: 700;
                @include mq(sm) {
                    width: 100%;
                    font-size: 1.5rem;
                    text-align: center;
                }
            }
        }

        .rsb-map {
            height: 0;
            overflow: hidden;
            padding-bottom: 80%;
            position: relative;
            @include mq(sm) {
                padding-bottom: 120%;
            }
            iframe {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    &-box {
        background-color: white;
        border-radius: 2.4rem;
        box-shadow: 0 7px 20px 4px rgba($clr-main,.15);
        overflow: hidden;
        @include mq(sm) {
            border-radius: 1.6rem;
        }
    }
}



@keyframes line-move-x {
    0% { background-position-x: 0; }
    100% { background-position-x: 15em; }
}

@keyframes line-move-y {
    0% { background-position-y: 0; }
    100% { background-position-y: 15em; }
}
