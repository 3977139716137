
/* Media screen settings
========================================================================== */

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        // @if $type == max {
        //   $width: $width - 1px;
        // }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }

    @else if $type == min {
        $width: map_get($breakpoints, $width);
        @media only screen and (min-width: $width) {
            @content;
        }
    }
}

/* Other Media screen settings
========================================================================== */

@mixin mq-max($size) {
    @media only screen and (max-width: $size) {
        @content;
    }
}

@mixin mq-min($size) {
    @media only screen and (min-width: $size) {
        @content;
    }
}

@mixin mq-min-max($min_size,$max_size) {
    @media screen and (min-width: $min_size) and (max-width: $max_size) {
        @content;
    }
}
