/* --------------------------------------------------------------------------
  slider
--------------------------------------------------------------------------- */

.p-slider {
    //width: 100%;
    width: calc(100% - 25rem);
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    @include mq-max('1200px') {
        width: 76%;
    }
    @include mq(sm) {
        width: 100%;
    }

    &-body {
        margin: 0 -2.5rem;
        @include mq(md) { margin: 0 -1.5rem; }
        @include mq(sm) { margin: 0 0; }
        .slick-slide {
            margin: 0 2.5rem;
            @include mq(md) { margin: 0 1.5rem; }
            @include mq(sm) { margin: 0 1rem; }
        }
    }
    &-nav {
        &__prev,&__next {
            display: block;
            width: 6rem;
            height: 6rem;
            line-height: 6rem;
            border-radius: 50%;
            background-color: white;
            border: 1px solid $clr-sub01;
            text-align: center;
            font-size: 1.4rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: .2s all;
            @include mq(md) {
                width: 5rem;
                height: 5rem;
                line-height: 5rem;
            }

            i { display: inline-block; }
            &:hover {
                background-color: $clr-sub01;
                color: white;
            }
            &:active { top: 51%; }
        }
        &__prev {
            left: -10rem;
            @include mq(inr) { left: -8rem; }
            @include mq(md) { left: -7rem; }
            @include mq(sm) { left: .5rem; }
            i { margin-right: 4px; }
        }
        &__next {
            right: -10rem;
            @include mq(inr) { right: -8rem; }
            @include mq(md) { right: -7rem; }
            @include mq(sm) { right: .5rem; }
            i { margin-left: 4px; }
        }
    }
}
