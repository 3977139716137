/* --------------------------------------------------------------------------
  var
--------------------------------------------------------------------------- */

$serch-section-height: 100px;
$select-h: 56px;

$mo-serch-section-height: 210px;
$mo-select-h: 46px;

/* --------------------------------------------------------------------------
  keyvisual
--------------------------------------------------------------------------- */

.p-keyvisual {
    width: 100%;
    overflow: hidden;
    background-color: $clr-mvbg;
    background-image: url(../img/bg_wave.png);
    background-repeat: repeat;
    background-size: 13% auto;
    height: calc(100vh - #{$serch-section-height});
    min-height: 720px;
    position: relative;
    @include mq(lg) {
        height: auto;
        min-height: initial;
    }
    @include mq(md) {
        height: calc(100vh - #{$serch-section-height});
        background-size: 20% auto;
    }
    @include mq(sm) {
        height: auto;
        // height: calc(100vh - #{$mo-serch-section-height});
        background-size: 30% auto;
    }

    &-char {
        width: 32%;
        position: absolute;
        top: 50%; left: 10%;
        transform: translateY(-60%);
        z-index: 2;
        @include mq-max('1470px') {
            left: 8%;
        }
        @include mq-max('1330px') {
            left: 6%;
        }
        @include mq-max('1200px') {
            left: 6%;
        }
        @include mq(md) {
            position: relative;
            top: inherit; left: inherit;
            width: 100%;
            transform: translateY(0);
            margin: 0 0 4rem;
        }
        @include mq(sm) {
            margin: 0 0 2rem;
        }

        .logo {
            width: 30%;
            margin: 0 auto 3vw;
            @include mq(md) {
                width: 20%;
                max-width: 100px;
                margin: 4rem auto 3rem;
            }
            @include mq(sm) {
                margin: 3rem auto 2rem;
            }
            @media (max-width: 768px) and (max-height: 924px) {
                width: 15%;
            }
            @media (max-width: 640px) and (max-height: 924px) {
                width: 20%;
            }
        }
        .main-txt {
            margin: 0 0 3vw;
            @include mq(md) {
                width: 70%;
                max-width: 460px;
                margin: 0 auto 2rem;
            }
            @media (max-width: 768px) and (max-height: 924px) {
                width: 50%;
            }
            @media (max-width: 640px) and (max-height: 924px) {
                width: 70%;
            }
        }
        .sub-txt {
            text-align: center;
            font-size: 1.3vw;
            font-weight: 700;
            line-height: 2;
            text-indent: .2em;
            letter-spacing: .2em;
            @include mq(md) {
                font-size: 2.3vw;
                line-height: 1.6;
            }
            @include mq(sm) {
                font-size: 3.4vw;
            }
        }
    }

    &-map {
        width: auto;
        height: 97%;
        position: absolute;
        bottom: 0; right: 3%;
        text-align: center;
        @include mq-max('1470px') {
            right: 0;
        }
        @include mq-max('1330px') {
            right: -2%;
        }
        @include mq-max('1300px') {
            right: -4%;
        }
        @include mq-max('1250px') {
            right: -6%;
            height: 92%;
        }
        @include mq(lg) {
            width: 80%;
            max-width: 700px;
            position: relative;
            bottom: 0;
            margin: 5rem 0 0 auto;
        }
        @include mq(md) {
            width: 85%;
            max-width: inherit;
            height: auto;
            position: absolute;
            bottom: 0; right: 4%;
            margin: 0 0 0 auto;
        }
        @include mq(sm) {
            position: relative;
            bottom: 0;
            width: 86%;
            max-width: inherit;
            right: 3%;
            // right: inherit;
            // width: 100%;
            // text-align: center;
        }
        img {
            width: auto;
            height: 100%;
            @include mq(lg) {
                width: 100%;
                height: auto;
            }
            @include mq(md) {
                width: 100%;
                height: auto;
            }
            @media (max-width: 768px) and (max-height: 924px) {
                width: auto;
                max-height: 350px;
            }
            @media (max-width: 640px) and (max-height: 924px) {
                width: 100%;
                height: auto;
                max-height: inherit;
            }
            // @include mq(sm) {
            //   width: auto;
            //   // height: 40vh;
            //   height: calc(100vh - #{$mo-serch-section-height} - 270px);
            // }
        }
    }
}


/* --------------------------------------------------------------------------
  serch secttion
--------------------------------------------------------------------------- */

.serchSec {
    height: $serch-section-height;
    background-color: $clr-sub01;
    display: flex;
    align-items: center;
    margin: 0 0 6rem;
    @include mq(sm) {
        height: auto;
        // height: $mo-serch-section-height;
        margin: 0 0 3rem;
    }


    &-inr {
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq(sm) {
            flex-wrap: wrap;
            padding: 2.5rem 3%;
        }
    }

    &-select {
        width: 100%;
        max-width: 360px;
        margin-right: 1.5rem;
        position: relative;
        @include mq-max('960px') {
            width: 40%;
            margin-right: 1rem;
        }
        @include mq(sm) {
            width: 100%;
            max-width: inherit;
            margin: 0 0 1rem;
        }


        .select2-container--default .select2-selection--single {
            height: $select-h;
            border-radius: 5px;
            font-size: 1.5rem;
            @include mq(sm) {
                height: $mo-select-h;
            }
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered {
            line-height: $select-h;
            @include mq(sm) {
                line-height: $mo-select-h;
            }
        }
        .select2-container .select2-selection--single .select2-selection__rendered {
            padding: 0 3em 0 4em;
            @include mq(sm) {
                padding: 0 2em 0 3em;
            }
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
            width: 4rem;
            b {
                border: none;
                display: block;
                width: 1.2rem;
                height: 1rem;
                margin: 0 0;
                transform: translate(-50%,-50%);
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2212%22%20height%3D%2211%22%20viewBox%3D%220%200%2012%2011%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.855%200.60223C11.6431%200.234201%2011.2528%200%2010.829%200H1.171C0.747209%200%200.356874%200.234201%200.144978%200.60223C-0.0557651%200.97026%20-0.0446127%201.41636%200.167283%201.78439L4.99628%209.69145C5.20818%2010.0372%205.58736%2010.2491%206%2010.2491C6.41264%2010.2491%206.79182%2010.0372%207.00371%209.69145L11.8327%201.78439C12.0446%201.41636%2012.0558%200.97026%2011.855%200.60223Z%22%20fill%3D%22%23282C37%22%2F%3E%3C%2Fsvg%3E');
                background-repeat: no-repeat;
                background-size: contain;
                transition: .2s all;
                @include mq(sm) {
                    width: 1rem;
                    height: .8rem;
                }
            }
        }
        .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
            b {
                transform: translate(-50%,-50%) rotate(180deg);
            }
        }

        &__icon {
            display: block;
            width: 2.3rem;
            position: absolute;
            top: 50%; left: 5%;
            transform: translateY(-50%);
            z-index: 10;
            @include mq(sm) {
                width: 2rem;
                left: 4%;
            }
        }
    }

    &-btn {
        width: 15rem;
        height: $select-h;
        line-height: $select-h;
        background-color: $clr-main;
        text-align: center;
        font-size: 1.7rem;
        font-weight: 700;
        color: white;
        border-radius: 5px;
        text-indent: 1rem;
        letter-spacing: 1rem;
        transition: .2s all;
        @include mq(sm) {
            width: 100%;
            height: $mo-select-h;
            line-height: $mo-select-h;
        }
        &:hover {
            background-color: darken($clr-main,15%);
        }
        &:active {
            transform: translateY(2px);
        }
    }
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 350px;
    overflow-y: auto;
}

.select2-container--open .select2-dropdown--above {
    overflow: hidden;
    box-shadow: 0 -10px 10px rgba(#000,.1);
}

.select2-container--open .select2-dropdown--below {
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(#000,.1);
}

.select2-results__option {
    font-size: 1.5rem;
    padding: 1rem 5% !important;
}

.select2-results__option--group {
    padding: 0 !important;
}

.select2-results__group {
    font-size: 1.4rem;
    background-color: #f5f5f5;
    color: #b9b9b9;
    padding: 1em 3% !important;
}
