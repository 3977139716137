/* --------------------------------------------------------------------------
  tab
--------------------------------------------------------------------------- */

.sw-tab {
    &-list {
        display: flex;
    }

    &-panel { display: none; }
    .is-tab-current { display: block; }
}
