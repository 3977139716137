/* --------------------------------------------------------------------------
  card
--------------------------------------------------------------------------- */

.c-card {
    height: 100%;
    a {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: .2s all;
    }
    &-thum {
        border-radius: 6px;
        overflow: hidden;
        margin: 0 0 2rem;
    }
    &-ttl {
        font-size: 1.7rem;
        font-weight: 700;
        margin: 0 0 1.5rem;
    }
    &-desc {
        font-size: 1.3rem;
        line-height: 1.6;
    }
}


.c-pnCard {
    a {
        padding-bottom: 4rem;
        border-bottom: 2px solid $gray03;
        position: relative;
        &::before {
            font-family: 'tdmo_icons';
            font-weight: normal;
            font-style: normal;
            font-display: block;
            content: "\e902";
            font-size: 2.2rem;
            color: $gray03;
            position: absolute;
            bottom: .8rem; right: 0;
            transition: .2s all;
        }
        &:hover {
            border-color: $clr-main;
            &::before { color: $clr-main; }
        }
    }
}

.c-tiCard {
    a {
        &:hover {
            opacity: .8;
        }
    }

    &-desc {
        padding-bottom: 2rem;
    }

    &-info {
        color: white;
        font-weight: 500;
        background-color: $clr-sub01;
        border-radius: 3px;
        padding: 1rem 5%;
        margin-top: auto;
        position: relative;
        &::before {
            content: "";
            display: block;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 10px solid $clr-sub01;
            position: absolute;
            top: -9px;left: 50%;
            transform: translateX(-50%);
        }
        i {
            color: $clr-act01;
            font-size: 1.8rem;
            vertical-align: -10%;
            margin-right: .5rem;
        }
        &__inr {
            display: flex;
            justify-content: center;
        }
    }
}
