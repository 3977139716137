/* --------------------------------------------------------------------------

--------------------------------------------------------------------------- */

.c-sec-titlebar {
    text-align: center;
    margin: 0 0 6rem;
    @include mq(sm) {
        margin: 0 0 4rem;
    }
    .eng-img {
        display: inline-block;
        opacity: .3;
        img {
            display: inline-block;
            width: auto;
            height: 12rem;
            @include mq(inr) { height: 18vw; }
            @include mq(md) { height: 13vw; }
            @include mq(sm) { height: 17vw; }
        }
    }
    .txt {
        font-size: 3.2rem;
        font-weight: 700;
        text-indent: .2em;
        letter-spacing: .2em;
        transform: translateY(-110%);
        @include mq(md) { font-size: 2.6rem; }
        @include mq(sm) { font-size: 2.2rem; }
    }
}
